/* general styling */
:root {
    --smaller: .75;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    height: 100%;
    margin: 0;
}

body {
    align-items: center;
    background-color: #0502d6;
    background-size: contain;
    background-repeat: no-repeat;
    // background-size: cover;
    background-position: center top;
    display: flex;
    font-family: "Segoe UI",
        Roboto, -apple-system,
        BlinkMacSystemFont Oxygen-Sans,
        Ubuntu,
        Cantarell,
        "Helvetica Neue",
        sans-serif;
}

.container {
    color: #f0f0f0;
    margin: 0 auto;
    text-align: center;
    background-color: #2c2c2ca9;
    
}

@media (min-width:700px) {
    .container {
        width: fit-content;
        display: block;
    }
}

h1 {
    font-weight: normal;
    letter-spacing: .125rem;
    text-transform: uppercase;
}

li {
    display: inline-block;
    font-size: 1.5em;
    list-style-type: none;
    padding: 1em;
    text-transform: uppercase;
}

li span {
    display: block;
    font-size: 4.5rem;
}

.emoji {
    display: none;
    padding: 1rem;
}

.emoji1 {
    display: none;
    padding: 1rem;
}

.emoji span {
    font-size: 4rem;
    padding: 0 .5rem;
}

@media all and (max-width: 768px) {
    h1 {
        font-size: calc(1.5rem * var(--smaller));
    }

    li {
        font-size: calc(1.125rem * var(--smaller));
    }

    li span {
        font-size: calc(3.375rem * var(--smaller));
    }
}