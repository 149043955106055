:root {
  --smaller: .75;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  background-color: #0502d6;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;
  font-family: Segoe UI, Roboto, -apple-system, BlinkMacSystemFont Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  display: flex;
}

.container {
  color: #f0f0f0;
  text-align: center;
  background-color: #2c2c2ca9;
  margin: 0 auto;
}

@media (min-width: 700px) {
  .container {
    width: -moz-fit-content;
    width: fit-content;
    display: block;
  }
}

h1 {
  letter-spacing: .125rem;
  text-transform: uppercase;
  font-weight: normal;
}

li {
  text-transform: uppercase;
  padding: 1em;
  font-size: 1.5em;
  list-style-type: none;
  display: inline-block;
}

li span {
  font-size: 4.5rem;
  display: block;
}

.emoji, .emoji1 {
  padding: 1rem;
  display: none;
}

.emoji span {
  padding: 0 .5rem;
  font-size: 4rem;
}

@media (max-width: 768px) {
  h1 {
    font-size: calc(1.5rem * var(--smaller));
  }

  li {
    font-size: calc(1.125rem * var(--smaller));
  }

  li span {
    font-size: calc(3.375rem * var(--smaller));
  }
}

/*# sourceMappingURL=index.5a46ad7b.css.map */
